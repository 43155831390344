.App {
  padding: 5px 20px 20px 20px;
}

.App-logo {
  height: 1vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #F0F0F0;
  min-height: 1vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 0.3vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.textarea-label {
  padding-right: 0.25em;
  display: inline-block;
  vertical-align: top;
}

.my-genotype {
  padding-right: 0.25em;
  display: inline-block;
  vertical-align: middle;
}

.my-status-icon {
  padding-right: 0.25em;
  display: inline-block;
  vertical-align: middle;
}

.genome-sun {
  height: 2vmin;
  vertical-align: top;
  pointer-events: none;
}

.App-Title {
  text-align: left;
  font-size: 2em;
  margin-left: 6em;
}

.Event-Title {
  text-align: left;
  font-size: 3em;
  font-weight: bold;
  margin-left: 90px;
}

.Event-Subtitle {
  text-align: left;
  font-size: 2em;
  font-weight: bold;
  margin-left: 90px;
  margin-bottom: 15px;
}

.user-instruction {
  horiz-align: left;
  font-style: oblique;
  font-size: 0.9em;
}

.find-genome-table  {
  width: 792px;
  margin-bottom: 0.5em;
  font-size: 0.9em;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
}

.find-genome-table .choose-file-td {
  font-style: oblique;
  padding: 0.35em;
  text-align: left;
  width: 400px;
}

.find-genome-table .then-td {
  font-style: oblique;
  padding-right: 2em;
  text-align: left;
  width: 60px;
}
.find-genome-table .ingest-td {
  font-style: oblique;
  padding: 0.35em;
  text-align: left;
  width: 300px;
}
#table-container::after, #table-container::before {
  display: flex;
  content: "";
  height: 2px;
  background: black;
  width: 100%;
}

.groups-selection-box {
  padding: 5px;
  border: 2px solid black;
}

.groups-selection-wrapper {
  padding: 0px;
}

.search-box {
  padding: 5px;
  border: 2px solid black;
}

.search-form {
  padding: 0px;
}

.search-error {
  color: red;
}

.deleter {
  color: red;
}

.button-panel {
  padding-bottom: 0px;
}

.lookup-lozenge {
  border-radius: 9px;
}

.success-message {
  color: green;
}

.error-message {
  color: red;
}

.error-details {
  font-size: smaller;
}

.left-aligned-paragraph {
  horiz-align: left;
}

.checked {
  color: green;
}

.unchecked {
  color: red;
}

.actions-table thead {
  position: sticky;
  top: 0px;
  margin: 8px 0 8px 0;
  padding: 8px 0 8px 0;
  background-color: #F5F5F5;
  font-weight: bold;
}

.actions-table .headings-row {
  font-weight: bold;
}

.action-cell {
  text-align: right;
}

.color-avoid-reduce {
  color: #FF0000;
}

.color-increase {
  color: darkgreen;
}

.color-lab-testing {
  color: #0000FF;
}

.actions-table .histogram-cell {
  display: inline-block;
  text-align: left;
}


.snp-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.snp-table td {
  padding: 0 4px 0 4px;
}

.snp-table .mild-emphasis {
  font-style: oblique;
}

.snp-table thead {
  position: sticky;
  top: 0px;
  margin: 8px 0 8px 0;
  padding: 8px 0 8px 0;
  background-color: #F5F5F5;
}

.snp-table .gene-table-header-upper-row {
  margin-inside: 400px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
  box-shadow: inset 0 2px 0 black;
}

.snp-table .gene-table-header-middle-row {
  margin: 0;
  padding: 0;
}

.snp-table .gene-table-header-middle-row-cell {
  margin: 0;
  padding: 0;
  height: 1px;
}

.snp-table .gene-table-header-middle-row-cell-line {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
  box-shadow: inset 0 1px 0 black, inset 0 0px 0 black;
}

.snp-table .gene-table-header-lower-row {
  margin: 8px 0 8px 0;
  padding: 8px 0 8px 0;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
  box-shadow: inset 0 0px 0 black, inset 0 -2px 0 black;
}

.snp-table thead tr th {
  margin: 0px 0 0px 0;
  padding: 4px 0 4px 0;
}

.snp-table .gibble {
  text-decoration: underline;
}

.snp-table .snibble {
  text-decoration: underline;
}

.snp-table .tibble {
  text-decoration: underline;
}

.gene-table-head-center {
  text-align: center;
  margin: 0px 0 0px 0;
  padding: 0px 0 0px 0;
}

.gene-table-head-left {
  text-align: left;
  margin: 0px 0 0px 0;
  padding: 0px 0 0px 0;
}

gene-table-head-min {
  width: 2em;
  background-color: rgba(0,  .5, 0,.5);
}

.snp-table .table-row-rsid-separator {
  border-bottom: 1px solid black;
}

.snp-table .table-row-bottom {
  border-bottom: 3px solid black;
}

.snp-table .table-cell-gene-name {
  font-weight: bold;
  text-align: center;
  width: 5em;
}

.snp-table .table-cell-variant-table {
  text-align: center;
  vertical-align: top;
}

.snp-table .variant-table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 50em;
  text-align: center;
}

.snp-table .variant-table-row {
  border-top: 1px solid black;
}

.cell-bottom-border {
  border-bottom: 1px solid black !important;
}

.snp-table .table-cell-info-text {
  text-align: left;
  vertical-align: top;
}

.snp-table .div-info-text {
  text-align: left;
  vertical-align: top;
  padding-top: 0.14em;
  padding-bottom: 0.14em;
}

.snp-table .table-cell-variant-name {
  width: 6em;
  text-align: center;
}

.snp-table .table-cell-rsId {
  width: 6em;
  text-align: center;
}

.snp-table .table-cell-my-genotype {
  width: 6em;
  text-align: right;
}

.snp-table .table-cell-allele-table {
  width: 64em;
  padding-top: 0.25em;
  text-align: left;
}

.snp-table .table-cell-allele {
  padding-right: 1em;
}

.snp-table .table-cell-status-icon {
  width: 2em;
  padding-right: 1em;
}

.snp-table .risk-allele {
  display: inline-block;
}

.snp-table .my-status-icon {
  display: inline-block;
  margin-top: -4.5px;
}

.genome-status-icon {
  height: 28px;
  width: 28px;
}

.hourglass-status-icon {
  height: 20px;
  width: 20px;
}

.sun-status-icon {
  height: 20px;
  width: 20px;
}

.homozygous_risk_icon {
  height: 10px;
  width: 10px;
  font-size: x-large;
  color: black;
}

.heterozygous_risk_icon {
  height: 10px;
  width: 10px;
  font-size: x-large;
  color: lightgray;
}

.heterozygous_boon_icon {
  height: 10px;
  width: 10px;
  font-size: xx-large;
  color: orange;
}

.homozygous_boon_icon {
  height: 10px;
  width: 10px;
  font-size: xx-large;
  color: green;
}

.snp-table .variant-health-risks {
}

.snp-table .allele-health-risks {
  width: 32em;
}

.rsId-table-container {
  width: 16em;
  padding: 10px 10px 10px 10px;
  margin-bottom: 1.5em;
}

.rsId-table-container-scroll {
  background-color: #F5F5F5;
  border-radius: 5px;
  width: 16rem;
  max-height: 12rem;
  overflow: scroll;
}

.rsId-table-container-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.rsId-table-container-scroll::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.rsId-table thead {
  position: sticky;
  top: 0px;
  margin: 0 0 0 0;
  background-color: #F5F5F5;
}

.rsId-table {
}

.rsId-table th {
  border-bottom: 2px solid black;
}

.rsId-table-name-th, .rsId-table-name-td {
  font-family: monospace, monospace;
  text-align: right;
  padding: 5px;
}

.rsId-table-name-th {
  padding-right: 20px;
}

.rsId-table-genome-th, .rsId-table-genome-td {
  text-align: left;
  padding: 5px;
}

.footer {
  background-color: #F0F0F0;
  min-height: 1vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 0.2vmin);
  text-align: left;
}
